<h3 mat-dialog-title *ngIf="title">
    {{ title }}
</h3>
<mat-dialog-content>
    <span>
        {{ message }}
    </span>
</mat-dialog-content>
<mat-dialog-actions class="mt-3" align="end">
    <button mat-raised-button
            cdkFocusInitial
            color="primary"
            type="button"
            [mat-dialog-close]="false">
        OK
    </button>
</mat-dialog-actions>