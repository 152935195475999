import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-info-component',
  templateUrl: './info-component.component.html',
  styleUrls: ['./info-component.component.scss']
})
export class InfoComponentComponent implements OnInit {

  message = '';
  title;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { message: string, title?: string }) {
  }


  ngOnInit(): void {
    this.message = this.data.message
    this.title = this.data.title
  }



}
